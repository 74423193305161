.time_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0px;
}

.time_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(50% - 1px);
}

.time_box label {
  margin-bottom: -0.5em;
  color: rgb(118, 118, 118);
}

.blueprint_container {
  width: 480px;
  height: 570px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
}

.mini_box {
  width: 10.8px;
  height: 10.8px;
  background-color: transparent;
}

/* .c-sidebar {
  background: #240325 !important;
  flex: 0 0 200px;
  width: 200px;
} */

.loader {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid #01af15; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.css-1u2oq74 {
  display: flex;
  justify-content: center;
}